import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide_WhatOptionalElements = () => {
    const seo = {
        metaDesc: 'What Are Optional Elements Of A Provisional Patent? - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="What Are Optional Elements Of A Provisional Patent? - Inventiv.org" canonical='/patent-guide' seo={seo} />
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>What Are Optional Elements Of A Provisional Patent?</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                                <img width="300" height="300"
                                    src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
                                    class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
                                    data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
                                    data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
                                    srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w" />
                            </figure>
                            <div class="wpb_wrapper">
                                <h2>What Are Optional Elements Of A Provisional Patent?</h2>
                                <ol>
                                    <li>
                                        <h3><b><i>Claims</i></b></h3>
                                    </li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>This is not required in a PPA but it is required in a utility patent
                                    application.&nbsp; You should think about these because patent protection rest on what claims you are
                                    asserting in your application.&nbsp; They lay the borders of what is protected by your patent. The USPTO
                                    sets guidelines about how these should be formatted.&nbsp;&nbsp;</span></p>
                                <p><span style={{ fontWeight: 400 }}>Because they are referenced in infringement suits, they should be crafted with
                                    care, be precise and understandable.&nbsp; Frequently people who wish to design around your patent
                                    manipulate the language used in your application to interpret your claims in a narrow light to justify that
                                    they do not infringe. They also make variations to try to achieve the same objective by using a different
                                    approach to avoid your claims. Hence, make sure your patent application text is detailed and inclusive of
                                    possible variations to avoid attempts to bypass your idea.&nbsp; But be careful not to make these variations
                                    necessary or required.</span></p>
                                <p>&nbsp;</p>
                                <ol start="2">
                                    <li>
                                        <h3><b><i>Prototype</i></b></h3>
                                    </li>
                                </ol>
                                <p><span style={{ fontWeight: 400 }}>A prototype is not a USPTO requirement.&nbsp; Because a PPA is relatively
                                    inexpensive, the inventor can describe reasonable ways to implement the invention on paper without
                                    prototyping. If enough details are disclosed, the PPA can become the basis of a valuable pioneering patent
                                    with the earliest possible priority date by avoiding prototyping delays.</span></p>
                                <p>&nbsp;</p>
                            </div>
                            <div className='mt-5'>
                                <p>
                                    <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide_WhatOptionalElements;